import { reactNode } from '@shared/node-v1.0.0'

import v200 from '@nodes/paper-v2.0.0'

export default reactNode(
	'Paper',
	{
		'v2.0.0': v200,
	},
	{ allowChildren: true, docs: 'https://docs.rolder.app/#/paper' }
)
