import { reactNode } from '@shared/node-v1.0.0'

import v200 from '@nodes/number-input-v2.0.0'
import v210 from '@nodes/number-input-v2.1.0'

const numberInput = reactNode(
	'NumberInput',
	{
		'v2.0.0': v200,
		'v2.1.0': v210,
	},
	{ allowChildren: true, docs: 'https://docs.rolder.app/#/numberinput' }
)

import lsV010 from '@nodes/number-input-left-section-v0.1.0'

const numberInputLeftSection = reactNode(
	'NumberInputLeftSection',
	{
		'v0.1.0': lsV010,
	},
	{ allowChildren: true, docs: 'https://docs.rolder.app/#/numberinput' }
)

import rsV010 from '@nodes/number-input-right-section-v0.1.0'

const numberInputRightSection = reactNode(
	'NumberInputRightSection',
	{
		'v0.1.0': rsV010,
	},
	{ allowChildren: true, docs: 'https://docs.rolder.app/#/numberinput' }
)

export { numberInput, numberInputLeftSection, numberInputRightSection }
