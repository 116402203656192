import { NumberInput, type NumberInputHandlers } from '@mantine/core'
import { sendOutput, sendSignal } from '@shared/port-send-v1.0.0'
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'
import type { Props } from '../node/definition'

export default forwardRef((p: Props, ref) => {
	const [defaultSetted, setFefaultSetted] = useState(false)
	const [value, setValue] = useState<string | number>(p.defaultValue === 0 ? p.defaultValue : p.defaultValue || '')
	const handlers = useRef<NumberInputHandlers>()

	useEffect(() => {
		if ((p.defaultValue === 0 || p.defaultValue) && !defaultSetted) {
			setValue(p.defaultValue === 0 ? p.defaultValue : p.defaultValue || '')
			setFefaultSetted(true)
		}
	}, [p.defaultValue])

	const children: any = p.children

	const leftSection = Array.isArray(children)
		? children.filter((i) => i?.props.noodlNode.model?.type.split('.')[2] === 'NumberInputLeftSection')?.[0]
		: children?.props.noodlNode.model?.type.split('.')[2] === 'NumberInputLeftSection'
			? children
			: null

	const rightSection = Array.isArray(children)
		? children.filter((i) => i?.props.noodlNode.model?.type.split('.')[2] === 'NumberInputRightSection')?.[0]
		: children?.props.noodlNode.model?.type.split('.')[2] === 'NumberInputRightSection'
			? children
			: null

	useEffect(() => {
		sendOutput(p.noodlNode, 'value', value)
		sendSignal(p.noodlNode, 'changed')
	}, [value])

	useImperativeHandle(
		ref,
		() => ({
			reset() {
				setValue(p.defaultValue === 0 ? p.defaultValue : p.defaultValue || '')
				sendOutput(p.noodlNode, 'value', '')
				sendSignal(p.noodlNode, 'reseted')
			},
			increment() {
				handlers.current?.increment()
			},
			decrement() {
				handlers.current?.decrement()
			},
		}),
		[]
	)

	return (
		<NumberInput
			value={value}
			onChange={setValue}
			handlersRef={handlers}
			clampBehavior={p.clamp ? 'strict' : 'blur'}
			leftSection={leftSection}
			rightSection={rightSection}
			{...R.libs.just.omit(p, 'children')}
			{...p.customProps}
		/>
	)
})
