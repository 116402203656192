import type { BaseReactProps, ReactNodeDef } from '@shared/node-v1.0.0'

export type Props = BaseReactProps & {}

import Comp from '../component'

export default {
	module: { static: Comp },
	afterNode: {
		validate: async (p, model) => {
			const parentNodeName = model.parent?.type.split('.')[2]
			if (parentNodeName === 'NumberInput') return true
			return `Parent of "NumberInputRightSection" must be "NumberInput", got "${parentNodeName}".`
		},
	},
} satisfies ReactNodeDef
